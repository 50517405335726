import {makeVar} from '@apollo/client';
import {UserApplicationScopes} from '../../interfaces/applications';

interface IAccount {
    userId: string,
    accessToken: string,
    accessTokenExpiration: number,
    permissions: UserApplicationScopes,
}

export const accountVar = makeVar<IAccount | null>(null);
export const userSettingsVar = makeVar({});
