import fetch from 'isomorphic-fetch';
import axios from 'axios';
import {accountVar} from './variables';

export const customFetch = (uri: string, options: RequestInit): Promise<Response> => {
    const refreshOptions = options;
    const account = accountVar();
    const currentTime = Math.floor(Date.now() / 1000);

    let refreshingPromise: Promise<string> | null = null;

    if (account !== null && account.accessTokenExpiration > (currentTime + 60)) {
        refreshOptions.headers = new Headers(options.headers);
        refreshOptions.headers.set('Authorization', `Bearer ${account.accessToken}`);
    }

    const initialRequest = fetch(uri, refreshOptions);

    return initialRequest
        .then(response => response.json())
        .then(json => {
            if (json && json.errors && json.errors[0] && json.errors[0].extensions.code === 'UNAUTHENTICATED') {
                if (refreshingPromise === null) {
                    refreshingPromise = fetch(`${process.env.GATSBY_API_BASE_URL}/auth/refreshToken`, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(result => result.json())
                        .then(result => {
                            accountVar(result.data);
                            if (typeof window !== 'undefined') {
                                localStorage.setItem('accountState', JSON.stringify({
                                    uid: result.data.userId,
                                    permissions: result.data.permissions,
                                }));
                            }

                            return result.data.accessToken;
                        })
                        .catch(() => {
                            if (typeof window !== 'undefined') {
                                localStorage.removeItem('accountState');
                            }

                            window.location.href = '/account/login';
                        });
                }

                return refreshingPromise.then(newAccessToken => {
                    const newOptions = options;

                    refreshingPromise = null;

                    newOptions.headers = new Headers(options.headers);
                    newOptions.headers.set('authorization', `Bearer ${newAccessToken}`);

                    return fetch(uri, newOptions);
                });
            }

            return new Response(JSON.stringify(json));
        })
        .catch((): never => {
            // window.location.href = '/500';
            throw new Error();
        });
};

interface CustomUploadOptions {
    body: string,
    onProgress?: (progress: number) => void,
    headers?: {
        Authorization?: string,
    },
}

export const customUploadFetch = (uri: string, options: CustomUploadOptions) => {
    const refreshOptions = options;
    const account = accountVar();
    const currentTime = Math.floor(Date.now() / 1000);

    if (account !== null && account.accessTokenExpiration > (currentTime + 60)) {
        if (refreshOptions.headers === undefined) {
            refreshOptions.headers = {};
        }

        refreshOptions.headers.Authorization = `Bearer ${account.accessToken}` || '';
    }

    return new Promise<Response>(resolve => {
        axios({
            url: uri,
            method: 'post',
            headers: refreshOptions.headers,
            data: refreshOptions.body,
            onUploadProgress: ({total, loaded}) => {
                const progress = Math.trunc((loaded / total) * 100);

                if (options.onProgress && typeof options.onProgress === 'function') {
                    options.onProgress(progress);
                }
            },
        })
            .then(res => {
                const result = new Response(JSON.stringify(res.data));

                resolve(result);
            })
            .catch(() => {
                if (typeof window !== 'undefined') {
                    localStorage.removeItem('accountState');
                }

                window.location.href = '/account/login';
            });
    });
};
