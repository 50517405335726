import {InMemoryCache} from '@apollo/client';
import {accountVar} from './variables';

export default new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                account: {
                    read() {
                        return accountVar();
                    },
                },
            },
        },
        User: {
            fields: {
                accessibleLanguages: {
                    merge(existing, incoming: any[]) {
                        return [...incoming];
                    },
                },
            },
        },
    },
});
